import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        modalView: false,
        pmprb: {
            pmprb_id: '',
            pmprb_nama: '',
            pmprb_ket: '',
            pmprb_file: '',
            pmprb_created_at: '',
        }
    },

    mutations: {
        toggleModal(state, value) {
            state.modalView = value
        },
        viewModal(state, value) {
            state.pmprb = value
        }
    }
})